const backgroundValue: Record<any, string> = {
  Light: 'surface',
  light: 'surface',
  Dark: 'surface.inverse',
  dark: 'surface.inverse',
}

const textColorValue: Record<any, string> = {
  Light: 'text',
  light: 'text',
  Dark: 'white',
  dark: 'white',
}

export const customColors = (type: 'background' | 'text' | 'title', theme: string, selectCustomColor: string) => {
  let returnValue: string = ''

  if (type === 'text') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = textColorValue[theme]
    }
  }

  if (type === 'title') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = textColorValue[theme]
    }
  }

  if (type === 'background') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = backgroundValue[theme]
    }
  }

  return returnValue
}

export const calculateFontStyles = (size: string | number) => {
  let fontSizeValue: string
  let fontWeightValue: number
  let lineHeightValue: string

  const parsedSize = typeof size === 'string' ? parseInt(size) : size

  switch (parsedSize) {
    case 700:
      fontSizeValue = '72px'
      fontWeightValue = 700
      lineHeightValue = '86.4px'
      break
    case 600:
      fontSizeValue = '56px'
      fontWeightValue = 700
      lineHeightValue = '67.2px'
      break
    case 500:
      fontSizeValue = '40px'
      fontWeightValue = 700
      lineHeightValue = '56px'
      break
    case 400:
      fontSizeValue = '28px'
      fontWeightValue = 700
      lineHeightValue = '39.2px'
      break
    case 300:
      fontSizeValue = '24px'
      fontWeightValue = 700
      lineHeightValue = '33.6px'
      break
    case 200:
      fontSizeValue = '20px'
      fontWeightValue = 700
      lineHeightValue = '30px'
      break
    case 100:
      fontSizeValue = '16px'
      fontWeightValue = 600
      lineHeightValue = '24px'
      break
    case 75:
      fontSizeValue = '14px'
      fontWeightValue = 600
      lineHeightValue = '21px'
      break
    case 50:
      fontSizeValue = '12px'
      fontWeightValue = 600
      lineHeightValue = '18px'
      break
    default:
      fontSizeValue = '40px'
      fontWeightValue = 700
      lineHeightValue = '48px'
  }

  return {
    fontSize: fontSizeValue,
    fontWeight: fontWeightValue,
    lineHeight: lineHeightValue,
  }
}
